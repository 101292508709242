import React from "react"
import DentistFormConfirmation from "../components/Form/DentistFormConfirmation"
import Layout from "../helper/layout"

const DentistFormConfirmationPage = () => {
  return (
    <Layout>
      <DentistFormConfirmation />
    </Layout>
  )
}

export default DentistFormConfirmationPage
